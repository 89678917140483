import {Component} from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import {ORIGIN, axios, auth0} from '../config'
import {loadAdminInterface, unloadAdminInterface} from '.'
import User from './User'
let Auth0Lock = null

const setAxiosToken = token => {
	if(!token) axios.defaults.headers.common['Authorization'] = null
	else axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

class Auth extends Component {
	constructor(props, context) {
		super(props, context)
		this.loadInitialState()
	}
	
	loadInitialState = () => {
		if (typeof window === 'undefined') return
    	let profile = localStorage.getItem('profile')
    	let token = localStorage.getItem('id_token')
    
    	if(profile && token) {
    		const dtoken = jwtDecode(token)
    		//console.log(dtoken, auth0, Date.now()/1000)
    		if(dtoken.aud!==auth0 || dtoken.exp < Date.now()/1000) {
				localStorage.removeItem('id_token')
   				localStorage.removeItem('profile')
   			} else {
   				try {
   					profile = JSON.parse(profile)
   					this.user = new User(profile)
   					setAxiosToken(token)
			    	//this.scheduleRenewal()
   					this.loadAdminInterface()
   				} catch (e) {
   					console.log(e)
   					localStorage.removeItem('id_token')
   					localStorage.removeItem('profile')
   				}
   			}
    	}
	}

	handleAuthentication = authResult => {
		this.lock.getUserInfo(authResult.accessToken, (error, profile) => {
			if (error) {
        		console.log(error);
    			alert(
        			'Error: ' + error.error + '. Check the console for further details.'
    			);
    			return
			}
		
			setAxiosToken(authResult.idToken)
			this.user = new User(profile)
			const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
			localStorage.setItem('expires_at', expiresAt)
			localStorage.setItem('profile', JSON.stringify(profile))
			localStorage.setItem('id_token', authResult.idToken)
			
			this.loadAdminInterface()
		})
	}

	doLogin = () => {
		if (!this.lock) {
            this.lock = new Auth0Lock(auth0, 'linuxship.eu.auth0.com', {
            	autoclose: true,
            	autofocus: true,
            	language: 'ro',
            	auth: {
            		redirect: false,
            		redirectUrl: ORIGIN + '/login',
            		responseType: 'token id_token',
					audience: 'https://linuxship.eu.auth0.com/userinfo',
					scope: 'openid profile roles',
            	}
            })
            this.lock.on("authenticated", this.handleAuthentication)
		}
        this.lock.show()
	}

	loadLock = () => {
		import ('auth0-lock').then(auth0LockModule => {
			if (this.unmounted) return
			Auth0Lock = auth0LockModule.default
			this.doLogin()
		})
	}

	getChildContext() {
        return {
            login: () => {
            	if (Auth0Lock) this.doLogin()
            	else this.loadLock()
            },
            logout: () => {
            	//clearTimeout(this.tokenRenewalTimeout)
				localStorage.removeItem('id_token')
   				localStorage.removeItem('profile')
   				localStorage.removeItem('expires_at')
      			unloadAdminInterface()
   				this.context.set({
   					user: null,
   				})
				this.forceUpdate()
            },
        }
    }

	loadAdminInterface = () => {
		loadAdminInterface()
			.then(() => {
				if (this.unmounted) return
				//this.context.set({themeLoad: true})
				this.context.set({user: this.user})
				this.forceUpdate()
			})
	}
	
	componentWillUnmount() {
		this.unmounted = true
    }
	
	render() {
		return this.props.children
	}
}
Auth.contextTypes = {
    set: PropTypes.func,
    get: PropTypes.func,
    addListener: PropTypes.func,
    removeListener: PropTypes.func
}
Auth.childContextTypes = {
    login: PropTypes.func,
    logout: PropTypes.func,
}

export default Auth