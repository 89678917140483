const intersection = {}

const intersectionCb = (entries, observer) => { 
	entries.forEach(entry => {
    	// Each entry describes an intersection change for one observed
	    // target element:
    	//   entry.boundingClientRect
    	//   entry.intersectionRatio
    	//   entry.intersectionRect
    	//   entry.isIntersecting
    	//   entry.rootBounds
    	//   entry.target
    	//   entry.time
    	entry.target.__fm.intersection(entry)
  	})
}
const initObserver = () => {
	try {
		intersection.observer = new IntersectionObserver(intersectionCb, {
  			root: null,
  			rootMargin: '0px',
  			threshold: 0
		})
	} catch (e) {
		console.log(e)
	}
}

const intersectionUnobserve = target => {
	if (!intersection.observer) return
	try {
		intersection.observer.unobserve(target)
	} catch(e) {
		console.log(e)
	}
}

const intersectionObserve = (target, cb) => {
	if (!intersection.observer) initObserver()
	try {
		target.__fm = {intersection: cb}
		intersection.observer.observe(target)
	} catch(e) {
		console.log(e)
	}
}
export {intersectionObserve, intersectionUnobserve}
