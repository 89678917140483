import React from 'react'
//import PropTypes from 'prop-types'
import {captcha} from '../config'
//import ReCAPTCHA from 'react-google-recaptcha'
/*
import makeAsyncScriptLoader from "react-async-script";

function getOptions() {
  return (typeof window !== "undefined" && window.recaptchaOptions) || {};
}
function getURL() {
  const dynamicOptions = getOptions();
  const lang = dynamicOptions.lang ? `&hl=${dynamicOptions.lang}` : "";
  const hostname = dynamicOptions.useRecaptchaNet ? "recaptcha.net" : "www.google.com";
  console.log(`https://${hostname}/recaptcha/api.js?onload=${callbackName}&render=explicit${lang}`)
  return `https://${hostname}/recaptcha/api.js?onload=${callbackName}&render=explicit${lang}`;
}

const callbackName = "onloadcallback";
const globalName = "grecaptcha";
const initialOptions = getOptions();
*/
/*
const export default makeAsyncScriptLoader(getURL, {
  callbackName,
  globalName,
  removeOnUnmount: initialOptions.removeOnUnmount || false,
})(ReCAPTCHA)
*/
let ReCAPTCHA = null

class Captcha extends React.Component {
	state = {
		isReady: ReCAPTCHA!==null,
		
	}
	/*
	constructor(props, context) {
		super(props, context)

		const lang = props.language
		window.recaptchaOptions = {
			lang,
			removeOnUnmount: true,
		}
		/*
		LReCAPTCHA = makeAsyncScriptLoader(getURL, {
			callbackName,
			globalName,
			removeOnUnmount: initialOptions.removeOnUnmount || false,
		})(ReCAPTCHA)* /
		this.state = {
			isReady: true, //ReCAPTCHA!==null,
			lang
		}
	}*/
	
	componentDidMount() {
		if (ReCAPTCHA) return
		this.reload()
	}

	componentWillUnmount() {
		this.unmounted = true
	}

	/*
	componentDidUpdate() {
		//console.log(this.captcha)
		//if (this.captcha && this.captcha.props.grecaptcha) this.captcha.props.grecaptcha.reset()
		if (ReCAPTCHA) return
		this.reload()
	}*/
	
	reload = () => {
		import('react-google-recaptcha').then(module => {
			if (this.unmounted) return
			ReCAPTCHA = module.default
			this.setState({isReady: true})
		})
		
	}
	
	/*
	static getDerivedStateFromProps(props, state) {
		const lang = props.language
		//console.log(props, state)
		if (lang!==state.lang) {
			//console.log('change lang', lang)
			window.recaptchaOptions.lang = lang
			//grecaptcha.reset();
			/*LReCAPTCHA = makeAsyncScriptLoader(getURL, {
				callbackName,
				globalName,
				removeOnUnmount: initialOptions.removeOnUnmount || false,
			})(ReCAPTCHA)* /
			return {isReady: true, lang}
		}
		return state
	}
	componentWillUnmount() {
		this.unmounted = true
	}*/
	
	onChange = captcha => {
		if (this.unmounted) return
		if (this.props.onChange) this.props.onChange(captcha)
	}	
	render() {
		const {language} = this.props
		if (!this.lang || this.lang!==language) {
			this.lang = language
			if (typeof window !== 'undefined') {
				window.recaptchaOptions = {
					lang: language,
					removeOnUnmount: true,
				}
			}
		}
		//if (LANGUAGES) window.recaptchaOptions.lang = lang
		
		return this.state.isReady
			?<ReCAPTCHA 
                	key={language} 
                	//ref={e=>this.captcha=e}
                	sitekey={captcha} onChange={this.onChange} />
        	:null
	}
}
/*Captcha.contextTypes = {
    get: PropTypes.func,
}*/

export default Captcha