import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { TimelineMax, TweenMax } from "gsap";
import Hammer from "hammerjs";
import FaIcon from "../../ui/FaIcon";
import LazyImage from "../../ui/LazyImage";
import Link from "../../ui/Link";
import Field from "../../ui/Field";
import { screen } from "../../lib/screen";
import { subscribe, unsubscribe } from "../../lib/pubsub";

class Slideshow extends Component {
  state = {
    active: 0,
    direction: null,
    prev: -1
  };

  componentDidMount() {
    this.hammer = new Hammer(document.querySelector(".slideshow"));
    this.hammer.on("swiperight", this.prev);
    this.hammer.on("swipeleft", this.next);
    this.rid = subscribe("resize", this.onResize);

    //this.resize()
    this.active = 0;
    const j = 0;
    this.t = new TimelineMax();
    this.t.pause();
    this.t.set(`.slide-${j} .front.right`, { css: { opacity: 0, x: 500 } });
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j}`,
        0.5,
        { x: 0, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.left`,
        2,
        { x: -200, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.right`,
        2,
        { x: 500, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.75
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h2`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h3`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.3
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .text`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.4
    );
    this.t.add(
      TweenMax.fromTo(`.slide-${j} a`, 0.5, { opacity: 0 }, { opacity: 1 }),
      2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line2`,
        1.5,
        { opacity: 0, scale: 2 },
        { opacity: 1, scale: 1 }
      ),
      2.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line1-inner`,
        1.5,
        { x: screen.W },
        { x: 0 }
      ),
      3.5
    );
    this.t.play();
    this.i = setTimeout(() => {
      this.goNext();
    }, 10000);
  }

  componentWillUnmount() {
    this.unmounted = true;
    if (this.i) clearTimeout(this.i);
    this.hammer.off("swiperight", this.prev);
    this.hammer.off("swipeleft", this.next);
    unsubscribe("resize", this.rid);
  }

  goPrev = () => {
    if (this.unmounted) return;
    this.prev();
  };
  goNext = () => {
    if (this.unmounted) return;
    this.next();
  };
  onResize = screen => {
    this.goNext();
  };
  /*
	resize = () => {
		console.log('resize track')
		if (!this.track) return
		const item = this.track.querySelector('.item.active')
		if (!item) return
		fastdom.measure(() => {
			if (this.unmounted) return
			const height = item.clientHeight
			fastdom.mutate(() => {
				if (this.unmounted) return
				this.track.style.height = `${height}px`
			})
		})
	}*/

  prev = e => {
    const nItems = this.props.value.length;
    const i = this.active;
    let j = i - 1;
    if (j < 0) j = nItems - 1;
    this.active = j;

    if (this.i) clearTimeout(this.i);
    if (this.t) this.t.progress(1);
    this.t = new TimelineMax();
    this.t.pause();
    this.t.set(`.slide-${j} .front.right`, { css: { opacity: 0, x: 500 } });
    this.t.add(
      TweenMax.fromTo(`.slide-${i}`, 0.5, { x: 0 }, { x: screen.W }),
      0
    );
    this.t.add(
      TweenMax.fromTo(`.slide-${j}`, 0.5, { x: -screen.W }, { x: 0 }),
      0
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.left`,
        2,
        { x: -200, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.right`,
        2,
        { x: 500, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.75
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h2`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h3`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.3
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .text`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.4
    );
    this.t.add(
      TweenMax.fromTo(`.slide-${j} a`, 0.5, { opacity: 0 }, { opacity: 1 }),
      2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line2`,
        1.5,
        { opacity: 0, scale: 2 },
        { opacity: 1, scale: 1 }
      ),
      2.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line1-inner`,
        1.5,
        { x: screen.W },
        { x: 0 }
      ),
      3.5
    );
    this.t.play();
    this.i = setTimeout(() => {
      this.goPrev();
    }, 10000);
  };

  next = e => {
    const nItems = this.props.value.length;
    const i = this.active;
    let j = i + 1;
    if (j >= nItems) j = 0;
    this.active = j;

    if (this.i) clearTimeout(this.i);
    if (this.t) this.t.progress(1);
    this.t = new TimelineMax();
    this.t.pause();
    this.t.set(`.slide-${j} .front.right`, { css: { opacity: 0, x: 500 } });
    this.t.add(
      TweenMax.fromTo(`.slide-${i}`, 0.5, { x: 0 }, { x: -screen.W }),
      0
    );
    this.t.add(
      TweenMax.fromTo(`.slide-${j}`, 0.5, { x: screen.W }, { x: 0 }),
      0
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.left`,
        2,
        { x: -200, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .front.right`,
        2,
        { x: 500, opacity: 0 },
        { x: 0, opacity: 1 }
      ),
      0.75
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h2`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} h3`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.3
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .text`,
        1.5,
        { opacity: 0, y: -50 },
        { opacity: 1, y: 0 }
      ),
      1.4
    );
    this.t.add(
      TweenMax.fromTo(`.slide-${j} a`, 0.5, { opacity: 0 }, { opacity: 1 }),
      2
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line2`,
        1.5,
        { opacity: 0, scale: 2 },
        { opacity: 1, scale: 1 }
      ),
      2.5
    );
    this.t.add(
      TweenMax.fromTo(
        `.slide-${j} .line1-inner`,
        1.5,
        { x: screen.W },
        { x: 0 }
      ),
      3.5
    );
    this.t.play();
    this.i = setTimeout(() => {
      this.goNext();
    }, 10000);
  };

  renderContent = slide => {
    const { data } = this.props;
    const language = data.language; //this.context.get('language')
    let s = slide;
    if (slide._i18n && slide._i18n[language]) s = slide._i18n[language];

    return (
      <Fragment>
        <div className="slide-content">
          {s.title && (
            <Field
              tag="h2"
              value={s.title}
              fieldInfo={{ type: "text" }}
              data={data}
              noadmin
            />
          )}
          {s.subtitle && (
            <Field
              tag="h3"
              value={s.subtitle}
              fieldInfo={{ type: "text" }}
              data={data}
              noadmin
            />
          )}
          {s.text && (
            <Field
              tag="div"
              value={s.text}
              className="text"
              fieldInfo={{ type: "text" }}
              data={data}
              noadmin
            />
          )}
          {s.path && (
            <Link className="button large" to={s.path}>
              {s.label}
            </Link>
          )}
        </div>
        {s.line1 && (
          <div className="lines">
            {s.line1 && (
              <div className="line1">
                <Field
                  tag="div"
                  value={s.line1}
                  className="line1-inner"
                  fieldInfo={{ type: "text" }}
                  data={data}
                  noadmin
                />
              </div>
            )}
            {s.line2 && (
              <Field
                tag="div"
                value={s.line2}
                className="line2"
                fieldInfo={{ type: "text" }}
                data={data}
                noadmin
              />
            )}
          </div>
        )}
      </Fragment>
    );
  };
  render() {
    const slides = this.props.value;
    const nItems = slides.length;

    return (
      <div className="slideshow">
        <div className="track" ref={ref => (this.track = ref)}>
          {slides.map((slide, i) => (
            <div key={i} className={`slide slide-${i}`}>
              {slide.img && (
                <LazyImage src={slide.img[0]} bg={true} className="bg" />
              )}
              {slide.front &&
                slide.front.length === 1 && (
                  <LazyImage
                    src={slide.front[0]}
                    bg={true}
                    noth={true}
                    className={`front ${slide.pos > 0 ? "right" : "left"}`}
                  />
                )}
              {slide.front &&
                slide.front.length === 2 && (
                  <Fragment>
                    <LazyImage
                      src={slide.front[0]}
                      bg={true}
                      noth={true}
                      className="front left"
                    />
                    <LazyImage
                      src={slide.front[1]}
                      bg={true}
                      noth={true}
                      className="front right"
                    />
                  </Fragment>
                )}
              {this.renderContent(slide)}
            </div>
          ))}
        </div>

        {nItems > 1 && (
          <div className="arrow prev" onClick={this.prev}>
            <FaIcon icon="faAngleLeft" />
          </div>
        )}
        {nItems > 1 && (
          <div className="arrow next" onClick={this.next}>
            <FaIcon icon="faAngleRight" />
          </div>
        )}
      </div>
    );
  }
}
Slideshow.contextTypes = {
  get: PropTypes.func
};

export default Slideshow;
