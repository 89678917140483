import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { WEBSITE_NAME } from "../../config";
//import {screen} from '../../lib/util'
import Link from "../../ui/Link";
import LanguageSwitcher from "./LanguageSwitcher";
import { HeaderAdminMenu } from "../../admin";
import FaIcon from "../../ui/FaIcon";

const menus = {
  ro: [
      { label: "Acasă", path: "/ro" },
      { label: "Despre companie", path: "/ro/despre-companie" },
      { label: "Produse", path: "/ro/produse" },
      { label: "Newsletter", path: "/ro/newsletters" },
      { label: "Contact", path: "/ro/contact" }
  ],
  en: [
      { label: "Home", path: "/en" },
      { label: "About company", path: "/en/about-the-company" },
      { label: "Products", path: "/en/products" },
      { label: "Newsletter", path: "/en/newsletters" },
      { label: "Contact", path: "/en/contact" }
  ],
  de: [
      { label: "Home", path: "/de" },
      { label: "Über das Unternehmen", path: "/de/uber-das-unternehmen" },
      { label: "Produkte", path: "/de/produkte" },
      { label: "Newsletter", path: "/de/newsletters" },
      { label: "Kontakt", path: "/de/kontakt" }
  ],
  hu: [
      { label: "Home", path: "/hu" },
      { label: "About company", path: "/hu/about-the-company" },
      { label: "Products", path: "/hu/products" },
      { label: "Newsletter", path: "/hu/newsletters" },
      { label: "Contact", path: "/hu/contact" }
  ]
};

export default class Header extends PureComponent {
  state = {
    user: null,
    menuClasses: "main-menu",
    visible: false
  };
  componentDidMount() {
    const user = this.context.get("user");
    const node = this.context.get("node");
    if (user) this.setState({ user });
    this.uid = this.context.addListener("user", this.handleUserChange);
    this._lang = this.context.addListener(
      "language",
      this.handleLanguageChange
    );

    if (node) this.setState({ visible: true });
    else this.nid = this.context.addListener("node", this.handleNodeChange);
  }
  componentWillUnmount() {
    this.unmounted = true;
    this.context.removeListener("user", this.uid);
    this.context.removeListener("language", this._lang);
    if (this.nid) this.context.removeListener("node", this.nid);
  }
  handleUserChange = user => {
    if (this.unmounted) return;
    this.setState({ user });
  };
  handleLanguageChange = language => {
    if (this.unmounted) return;
    this.setState({ language });
  };
  handleNodeChange = node => {
    if (this.unmounted || this.state.visible) return;
    this.setState({ visible: true });
  };
  openMenu = e => {
    this.setState({ menuClasses: "main-menu open" });
  };
  closeMenu = e => {
    this.setState({ menuClasses: "main-menu" });
    return true;
  };
  render() {
    const { visible, menuClasses } = this.state;
    const lang = this.context.get("language") || "ro";

    return (
      <header
        className={visible ? "header animated fadeInDown" : "header invisible"}
      >
        <Link to={`/${lang}`} className="logo">
          <img src={"/img/sigla-Betak.png"} alt={WEBSITE_NAME} />
        </Link>
        <HeaderAdminMenu />
        <div
          className="mobile-menu-icon"
          onClick={this.openMenu}
          style={{ width: 24 }}
        >
          <FaIcon icon="faBars" />
        </div>
        <LanguageSwitcher />
        <ul className={menuClasses}>
          {menus[lang].map((item, i) => (
            <li key={i}>
              <Link to={item.path} className="link" onActivate={this.closeMenu}>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </header>
    );
  }
}

Header.contextTypes = {
  get: PropTypes.func,
  addListener: PropTypes.func,
  removeListener: PropTypes.func
};
