import React from 'react'
import FaIcon from '../../ui/FaIcon'
import {realPath} from '../../lib/util'

const mime = {
	'application/pdf': 'faFilePdf'
}
const label = {
	ro: 'Fișa tehnică',
    en: 'Technical data',
    de: 'Technical data',
    hu: 'Technical data'
}
const DocRenderer = ({field, value, data}) => {
	if (!value || value.length===0) return null
	const icon = mime[value[0].mime] || null
	// console.log(field, value, data)
	return <a href={realPath(value[0].url)} className='button'>
		{icon && <FaIcon icon={icon} size='24'/> } {label[data.language]}</a>
}
export default DocRenderer
