//import {GA_TRACKING_ID, axios, API} from '../config'

const pageView = (node, language) => {
    if(!node) return

    const p = node.path.filter(path => path.lang===language)
    if(p.length===0) return
    const path = p[0].p

    let dynx_itemid = ''
    if (node.bundle==='product' && !!node.gid) dynx_itemid = node.gid
    const dynx_pagetype = node.bundle
    window.dataLayer.push({
        event: 'Pageview',
        pagePath: path,
        pageTitle: node.title,
        dynx_itemid,
        dynx_pagetype
    })
    if (node.bundle!=='product' || !node.gid) return
    window.dataLayer.push({
        event: 'view_item',
        items: [{
            id: node.gid,
            google_business_vertical: "custom"
        }]
    })
    //axios.get(`${API}/pageview${page_path}`)
}
export {pageView}
