import React from 'react'
import {realPath} from '../../lib/util'
import LazyImage from '../LazyImage'

const Img = ({entity, entityInfo, field, value, fieldInfo, data, ...props}) => {
	const Tag = props.tag || 'div'

	if (!value || value.length===0) return null
	
	if (value.length===1) {
		const mime = value[0].mime?value[0].mime.split('/'):'image'
		if (mime[0]==='video') {
			return <video autoPlay playsInline muted loop {...props}>
                        <source src={realPath(value[0].url)} type={value[0].mime}/>
                    </video>
		}
		if (mime[0]==='image') return <LazyImage src={value[0]} {...props}/>
	}
	
	return 	<Tag {...props}>
				{value.map((img, i) => {
				const mime = img.mime?img.mime.split('/'):'image'
				if (mime[0]==='video') 
					return <video autoPlay playsInline muted loop>
                        <source src={realPath(img.url)} type={img.mime}/>
                    </video>
				return <LazyImage src={img}/>
				})}
			</Tag>
}
export default Img
