import React from 'react'
import Auth from '../lib/Auth'

class Footer extends React.PureComponent {
	
	
	render() {
		return (
			<React.Fragment>
				<footer>
					<Auth/>
				</footer>
			</React.Fragment>
		)
	}
} 
export default Footer