let key = 0
let subscribers = {}

const subscribe = (topic, cb) => {
	if (!subscribers[topic]) subscribers[topic] = {}
	key++
	subscribers[topic][key] = cb
	return key	
}

const unsubscribe = (topic, key) => delete subscribers[topic][key]

const publish = (topic, ...args) => {
	if(!subscribers[topic]) return
	for (let key in subscribers[topic]) {
		subscribers[topic][key](...args)
	}
}

export {subscribe, unsubscribe, publish}
