//import {currentUser} from '../reducers/auth'
import auth from '../../../authorize'
let currentUser = null
let permissions = {}

/*
	{
		perm: {
			roles: [...],
			cb: null
		}
	}
*/
const createPermission = (perm, data) => { 
	permissions[perm] = data
}

const loggedIn = () => {
	const user = currentUser()
	if (!user) return false
	return true
}

const isAdmin = () => {
	const user = currentUser()
	if (!user) return false
	return user.roles.includes('admin')
}

const isEditor = () => {
	const user = currentUser()
	if (!user) return false
	return user.roles.includes('editor')
}

const userAccess = (perm, data=null) => {
	const user = currentUser()
	if(isAdmin()) return true
	if(!permissions[perm]) return false
	const pdata = permissions[perm]
	if (pdata.cb) return pdata.cb(user, perm, data)

	if (user && pdata.roles) return user.roles.reduce((acc, role) => pdata.roles.includes(role)||acc, false)
	
	return false
}
Object.keys(auth).forEach(perm => {
	if(perm==='entity') return
	createPermission(perm, {roles: auth[perm]})
})
export {createPermission, userAccess, isAdmin, isEditor, loggedIn}
