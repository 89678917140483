import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import FaIcon from '../../ui/FaIcon'
//import faLockAlt from '@fortawesome/fontawesome-free-solid/faLock'
//import faLockOpenAlt from '@fortawesome/fontawesome-free-solid/faLockOpen'

class Auth extends Component {
    constructor(props, context) {
    	super(props, context)
    	this.state = {user: context.get('user')}
    }

    componentDidMount() {
    	this.uid = this.context.addListener('user', this.handleUserChange)
    }
    
	componentWillUnmount() {
		this.unmounted = true
    	this.context.removeListener('user', this.uid)
    }
	
	handleUserChange = user => {
		if (this.unmounted) return
		this.setState({user})
	}

	render() {
	    const { login, logout } = this.context
	    const { user } = this.state
		
		return (
			<div className='auth'>
                {user
                    ? <div onClick={logout}><FaIcon icon='faLockOpen' /> <span>Deautentificare</span></div>
                    : <div onClick={login}><FaIcon icon='faLock' /> <span>Autentificare</span></div>
                }
 			</div>
    	);
  	}
}
Auth.contextTypes = {
    login: PropTypes.func,
    logout: PropTypes.func,
    get: PropTypes.func,
    addListener: PropTypes.func,
    removeListener: PropTypes.func
}
export default Auth
//<FontAwesomeIcon icon={faLockAlt} />
