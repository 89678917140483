let loaded = false
const loadFB = (target) => {
	const loadSDK = (d, s, id) => {
		//console.log(loaded)
  		var js, fjs = d.getElementsByTagName(s)[0];
  		if (loaded) return false
  		loaded = true
  		//if (window.FB) return true
  		if (d.getElementById(id)) return false;
  		js = d.createElement(s); js.id = id;
  		js.src = "//connect.facebook.net/ro_RO/sdk.js#xfbml=1&version=v2.12"; //#xfbml=1&version=v2.12
  		fjs.parentNode.insertBefore(js, fjs);
  		return true
	}
	if (!loadSDK(document, 'script', 'facebook-jssdk')) {
		if (!window.FB) return false
		window.FB.XFBML.parse(target.parentNode)
		return true
	}
	return true
}

export default loadFB
//https://connect.facebook.net/ro_RO/sdk.js#xfbml=1&version=v2.12