import Auth from './Auth'
const Stub = () => null
const StubProxy = props => props.children

//let muiTheme = null;
//let MuiThemeProvider = null
let HeaderAdminMenu = Stub
let FieldAdmin = StubProxy
let FieldManager = Stub

const loadAdminInterface = () => {
	return new Promise((resolve, reject) => {
		import('./auth').then(auth => {
			({HeaderAdminMenu, FieldAdmin, FieldManager} = auth) //MuiThemeProvider, 
    	  	resolve()
		})
	})
}

const unloadAdminInterface = () => {
	HeaderAdminMenu = Stub
	FieldAdmin = StubProxy
	FieldManager = Stub
}

export {
	Auth,

	//muiTheme, //MuiThemeProvider,
	HeaderAdminMenu,
	FieldAdmin,
	FieldManager,
	
	loadAdminInterface,
	unloadAdminInterface
}