const fieldTypes = {
  text: { dynamic: false },
  bool: { dynamic: false },
  class: { dynamic: false },
  radio: { dynamic: false },
  list: { dynamic: false },
  obj: { dynamic: false },
  custom: { dynamic: false },
  block: {
    name: "Container",
    type: "obj",
    fields: [{ name: "label", type: "text", label: "Etichetă" }]
  },
  link: {
    name: "Legătură",
    type: "obj",
    dynamic: false,
    fields: [
      { name: "label", type: "text", label: "Etichetă" },
      { name: "path", type: "text", label: "Legătură" }
    ]
  },
  slide: {
    name: "Slide",
    type: "obj",
    dynamic: false,
    _t: false,
    fields: [
      { name: "img", type: "img", label: "Imagine", _t: false },
      { name: "front", type: "img", label: "Produs", _t: false },
      {
        name: "pos",
        type: "bool",
        label: "La dreapta",
        display: "toggle",
        _t: false
      },
      { name: "title", type: "text", label: "Titlu", fullWidth: true },
      { name: "subtitle", type: "text", label: "Subtitlu", fullWidth: true },
      { name: "text", type: "text", label: "Text", fullWidth: true },
      { name: "label", type: "text", label: "Etichetă", fullWidth: true },
      { name: "path", type: "text", label: "Legătură", fullWidth: true },
      { name: "line1", type: "text", label: "Linie 1", fullWidth: true },
      { name: "line2", type: "text", label: "Linie 2", fullWidth: true }
    ]
  },
  slideshow: {
    name: "Slideshow",
    type: "list",
    items: { type: "slide" },
    _t: false
  },
  products: {
    name: "Produse",
    type: "custom",
    _t: false
  },
  contact: {
    name: "Contact",
    type: "form",
    fields: [
      { name: "intro", type: "html", label: "Introducere" },
      { name: "thanks", type: "html", label: "Multumiri" },
      { name: "labels", type: "text", label: "Etichete" }
    ],
    form: "Contact",
    formFields: [
      { name: "name", type: "text", label: "Numele Dvs." },
      { name: "company", type: "text", label: "Companie" },
      { name: "email", type: "text", label: "E-mail" },
      { name: "phone", type: "text", label: "Telefon" },
      { name: "message", type: "textarea", label: "Mesaj" }
    ]
  },
  map: { name: "Hartă", type: "text", _t: false },
  table: {
    name: "Tabel",
    type: "text",
    fullWidth: true,
    multiLine: true,
    rows: 5
  },
  patent: { name: "Patent", type: "text", _t: false },
  newsletters: {
    name: "Newsletters",
    type: "custom",
    _t: false
  },
  pdf: {
    name: "Fisier PDF",
    type: "obj",
    _t: false,
    fields: [
      { name: "label", type: "text", label: "Etichetă" },
      { name: "file", type: "doc", label: "Fisier" }
    ]
  },
};
export { fieldTypes };
