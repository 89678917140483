import React from "react";
import { realPath } from "../../lib/util";
import FaIcon from "../FaIcon";

const containerStyle = {
  position: "relative",
  overflow: "hidden"
};
const posterStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  cursor: "pointer",
  background: "rgba(0, 0, 0, 0.2)"
};
const buttonStyle = {
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 96,
  height: 96,
  color: "rgb(0, 119, 191)"
};

const Video = ({
  entity,
  entityInfo,
  field,
  value,
  fieldInfo,
  data,
  ...props
}) => {
  const [paused, setPaused] = React.useState(true);
  const ref = React.useRef();
  const Tag = props.tag || "div";
  const handlePause = React.useCallback(() => {
    setPaused(true);
  }, []);
  const handlePlay = React.useCallback(() => {
    ref.current.play();
    setPaused(false);
  }, []);
  const handlePlaying = React.useCallback(() => {
    setPaused(false);
  }, []);
  React.useEffect(
    () => {
      ref.current.addEventListener("pause", handlePause);
      ref.current.addEventListener("play", handlePlaying);
      /*if (ref.current.paused) {
        ref.current.play();
        setPaused(false);
      }*/
      return () => {
        ref.current.removeEventListener("play", handlePlaying);
        ref.current.removeEventListener("pause", handlePause);
      };
    },
    [handlePause, handlePlaying]
  );
  if (!value || value.length === 0) return null;

  if (value.length === 1) {
    return (
      <Tag {...props} style={containerStyle}>
        <video ref={ref} controls playsInline>
          <source src={realPath(value[0].url)} type={value[0].mime} />
        </video>
        {paused && (
          <div style={posterStyle} onClick={handlePlay}>
            <div style={buttonStyle}>
              <FaIcon icon="faPlay" />
            </div>
          </div>
        )}
      </Tag>
    );
  }

  return (
    <Tag {...props}>
      {value.map((img, i) => {
        return (
          <video controls autoPlay playsInline loop>
            <source src={realPath(img.url)} type={img.mime} />
          </video>
        );
      })}
    </Tag>
  );
};
export default Video;
