import React from "react";
import PropTypes from "prop-types";
import { getEntityField } from "../admin/auth/conf/entity";
import renderers from "./widgets/renderers";
import { FieldAdmin } from "../admin";
import { LANGUAGES } from "../config";

const Field = ({ value, noadmin, region, ...props }, context) => {
  const { entity, field } = props;

  const fieldInfo = props.fieldInfo || getEntityField(entity, field);
  if (!fieldInfo || fieldInfo.display === false) return null;

  let val = value || (entity ? entity[props.field] : null);
  if (LANGUAGES) {
    const lang = props.data.language;
    if (
      lang !== LANGUAGES[0] &&
      fieldInfo._t === undefined &&
      entity &&
      entity._i18n &&
      entity._i18n[lang] &&
      entity._i18n[lang][props.field] !== undefined
    )
      val = entity._i18n[lang][props.field];
  }

  const Renderer = renderers[fieldInfo.type];
  if (!Renderer) return null;

  const fieldProps = {};
  if (fieldInfo.id) fieldProps.id = fieldInfo.id;
  let classes =
    `field-${field} field-${fieldInfo.type} ` +
    (fieldInfo._class ? fieldInfo._class : "");

  if (noadmin)
    return (
      <Renderer
        value={val}
        className={classes}
        fieldInfo={fieldInfo}
        {...fieldProps}
        {...props}
      />
    );
  return (
    <FieldAdmin {...props}>
      <Renderer
        value={val}
        className={classes}
        fieldInfo={fieldInfo}
        {...fieldProps}
        {...props}
      />
    </FieldAdmin>
  );
};
Field.contextTypes = {
  get: PropTypes.func
};
export default Field;
