const onChangeListeners = [];

const prepareSearch = search => {
	if (!search) return search
	return search.slice(1).split('&').map(item => item.split('='))
}
const push = pathname => {
    window.history.pushState({pathname}, '', pathname);

    onChangeListeners.forEach(callback => callback(document.location.pathname, prepareSearch(document.location.search)));
}

const addLocationListener = cb => onChangeListeners.push(cb)
if (typeof window !== 'undefined') window.onpopstate = e => onChangeListeners.forEach(callback => callback(document.location.pathname, prepareSearch(document.location.search)))

export {
    push,
    addLocationListener
}
