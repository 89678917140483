import React from "react";
import PropTypes from "prop-types";
import { sendForm } from "../../lib/api";
import Captcha from "../../ui/Captcha";
import Field from "../../ui/Field";

class FormRenderer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sent: false,
      showThanks: false
    };

    const { fieldInfo } = props;
    const formSent =
      typeof window === "undefined"
        ? false
        : sessionStorage.getItem(`form-${fieldInfo.form}`);
    if (formSent) this.state.sent = true;
  }
  componentWillUnmount() {
    this.unmounted = true;
  }
  onTextChange = e => {
    const field = e.currentTarget.dataset.field;
    this.setState({ [field]: e.currentTarget.value });
  };
  onCaptcha = captcha => {
    if (this.unmounted) return;
    this.captcha = captcha;
  };
  renderField = (f, label) => {
    switch (f.type) {
      case "text":
        return (
          <input
            type="text"
            data-field={f.name}
            placeholder={label}
            value={this.state[f.name]}
            onChange={this.onTextChange}
          />
        );
      case "textarea":
        return (
          <textarea
            type="text"
            data-field={f.name}
            placeholder={label}
            value={this.state[f.name]}
            onChange={this.onTextChange}
          />
        );
      default:
        return null;
    }
  };

  onSend = e => {
    const { fieldInfo, entity } = this.props;
    //console.log(this.state, fieldInfo);

    const form = {
      values: {},
      form: fieldInfo.type,
      captcha: this.captcha,
      entity: entity.title,
      path: document.location.href
    };
    fieldInfo.formFields.forEach(
      f => (form.values[f.name] = this.state[f.name] || "")
    );
    //console.log(form);
    sendForm(form, result => {
      if (result.data.ok) {
        //console.log(result);
        this.setState({ sent: true, showThanks: true });
        sessionStorage.setItem(`form-${fieldInfo.form}`, true);
      }
    });
  };

  render() {
    const { sent, showThanks } = this.state;
    const { field, fieldInfo, value, data } = this.props;
    //console.log(this.props)
    //console.log(field, fieldInfo, entity)
    if (!fieldInfo.formFields) return null;

    const language = data.language; //this.context.get('language')
    let labels = value.labels;
    if (
      value &&
      value._i18n &&
      value._i18n[language] &&
      value._i18n[language].labels
    )
      labels = value._i18n[language].labels;
    if (labels) labels = labels.split(",");

    if (sent) {
      if (showThanks) {
        return <div className={`field-${field}`}>Thanks</div>;
      } else {
        return (
          <div className={`field-${field}`}>Formularul a fost trimis.</div>
        );
      }
    }

    return (
      <div className={`field-${field}`}>
        {value &&
          value.intro && (
            <Field
              noadmin
              field="intro"
              value={value.intro}
              fieldInfo={{ type: "html" }}
              data={data}
            />
          )}
        {fieldInfo.formFields.map((f, i) => (
          <div key={i} className={`form-field form-field-${f.type}`}>
            {this.renderField(f, labels ? labels[i] : f.label)}
          </div>
        ))}
        <Captcha onChange={this.onCaptcha} language={language} />

        <div className="send">
          <div className="button" onClick={this.onSend}>
            {labels ? labels[5] : "Trimite"}
          </div>
        </div>
      </div>
    );
  }
}
FormRenderer.contextTypes = {
  get: PropTypes.func
};
export default FormRenderer;
