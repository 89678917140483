import React, {PureComponent} from 'react'
import loadFB from '../lib/fb'
import fastdom from 'fastdom'
import {screen} from '../lib/screen'

class FBVideo extends PureComponent {
	state = {
		width: '100%'
	}
	componentDidMount() {
		this.doLoadFB()
	}
	
	watchHeight = () => {
		if (this.unmounted) return
		fastdom.measure(() => {
			if (this.unmounted) return
			const width = this.video.clientWidth
			const height = this.video.clientHeight
			if (width>220 && height < 100) {
				setTimeout(() => {
					if (this.unmounted) return
					this.watchHeight()
				})
			} else {
				//console.log(width, height)
				const maxHeight = screen.H * 0.8
				if (height > maxHeight) {
					const w = width * maxHeight / height
					this.setState({width: `${w}px`})
				}
			}
		})
	}
	
	doLoadFB = () => {
		if(loadFB(this.ref)) {
			this.watchHeight()
			return
		}
		setTimeout(() => {
			if (this.unmounted) return
			this.doLoadFB()
			this.watchHeight()
		})
	}
	
	componentWillUnmount() {
		this.unmounted = true
	}
	
	render() {
		const {url} = this.props
		const {width} = this.state
		
		return (
    		<div ref={ref => this.video=ref}>
    			<div style={{width, margin: '0 auto'}}>
				<div className="fb-video"
					ref={ref => this.ref=ref}
					data-href={url}
                	//data-width={w || 640}
                	data-allowfullscreen="true"
                	data-show-text="true"
                	data-show-captions="true"/>
                </div>
    		</div>
  		)
	}
}

export default FBVideo
//"https://developers.facebook.com/docs/plugins/" 