import bugsnag from "bugsnag-js";
import bugsnagReact from "bugsnag-react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { Auth } from "./admin";
import Layout from "./components/Layout";

window.bugsnagClient = bugsnag("b2b75b90d7fa76bede6848a9ec5b6078");
const ErrorBoundary = window.bugsnagClient.use(bugsnagReact(React));
if (window.initialStore) window.isHydrating = true;

ReactDOM.hydrate(
  <ErrorBoundary>
    <App>
      <Auth>
        <Layout />
      </Auth>
    </App>
  </ErrorBoundary>,
  document.getElementById("root"),
  () => {
    window.isHydrating = false;
    delete window.initialStore;
  }
);
//registerServiceWorker();
//unregister()
