const nodeBundles = {
  homepage: {
    name: "Home"
  },
  page: {
    name: "Pagină",
    fields: {
      title: { fullWidth: true }
    }
  },
  newsletter: {
    name: "Newsletter",
    fields: {
      title: { fullWidth: true }
    }
  },
  product: {
    name: "Produs",
    fields: {
      title: { fullWidth: true },
      parent: { bundle: "product", _t: false },
      img: { _t: false }
    }
  }
};

const entityTypes = {
  address: {
    name: "Adresa"
    //getLabel: entity => entity.title
  }
};
export { nodeBundles, entityTypes };
