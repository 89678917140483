import {LANGUAGES} from '../../config'
import {query, registerQuery, unregisterQuery} from '../../lib/api'

let qid = null
const pathField = LANGUAGES?'path.p':'path'
const buildQuery = location => ({
	query: {
		$or: [
			{[pathField]: location}, 
			{alias: location}
		]
	}
})

const customPages = {
	'/login': 'login',
	//'/': 'homepage',
}

const getNode = (location, cb=null) => {
	if (qid) unregisterQuery(qid)
	qid = null
	
	if (customPages[location]) return {query: null, data: {results: [{_id: {$oid: 0}, type: 'node', bundle: customPages[location]}]}}

	const q = buildQuery(location)
	const data = query(q)
	
	if (cb) qid = registerQuery(q, cb)
	return {query: q, data}
}

export default getNode
