import React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons/faLockOpen";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faUnlink } from "@fortawesome/free-solid-svg-icons/faUnlink";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";

const icons = {
  faBars,
  faFilePdf,
  faLink,
  faLock,
  faLockOpen,
  faUnlink,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPlay
};

const FaIcon = ({ icon, size }) => {
  if (!icons[icon]) return null;

  const iconData = icons[icon].icon;
  const width = iconData[0];
  const height = iconData[1];
  const path = iconData[4];
  let style = {};
  if (size) {
    style = {
      width: `${size}px`,
      height: `${size}px`
    };
  }

  return (
    <svg style={style} viewBox={`0 0 ${width} ${height}`}>
      {" "}
      xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d={path} />
    </svg>
  );
};
export default FaIcon;
