import React from 'react'
import getEntityInfo from '../admin/auth/conf/getEntityInfo'
import {FieldManager} from '../admin'
import Field from './Field'

const Region = (props, context) => {
	const {entity} = props
	const region = props.region || 'content'
	
	const info = getEntityInfo(entity)
	
	let o
	if (entity._o && entity._o[region]) {
		o = entity._o[region].split(',')
	} else {
		o = info.fields.map(f => f.name)
		o = [...info.fields]
		if (region==='content') o = o.filter(f => f.region===undefined || f.region==='content')
		else  o = o.filter(f => f.region===region)
		o = o.map(f=>f.name)
	}
	
	o = o.filter(f => !info.fields.filter(f1 => f1.name===f)[0]._block)

	return <div className={`region-${region}`}><div className='region-in'>
			{o.map((f,i) => <Field key={i} field={f} entityInfo={info} 
				fieldInfo={info.fields.filter(field => field.name===f)[0]} {...props}/>)}
			</div>
			<FieldManager entity={entity} region={region}/>
		</div>
}
export default Region
