import React from 'react'
import FaIcon from '../../ui/FaIcon'
import {realPath} from '../../lib/util'

const mime = {
    'application/pdf': 'faFilePdf'
}
const PDFLinkRenderer = ({field, fieldInfo, value, data}) => {
    if(fieldInfo._lang &&
       fieldInfo._lang.length>0 &&
       (!fieldInfo._lang.includes(data.language))) return null

    let val = value
    if (data.language && value && value._i18n && value._i18n[data.language])
        val = value._i18n[data.language]

    if (!val || (!val.file) || val.file.length===0) return null
    const icon = mime[val.file[0].mime] || null

    return <a href={realPath(val.file[0].url)} className='button pdf-link'>
	   {icon && <FaIcon icon={icon} size='24'/> } {val.label}</a>
}
export default PDFLinkRenderer
