import React from "react";
import Query from "../../lib/Query";
import Field from "../../ui/Field";
import Link from "../../ui/Link";

const Newsletter = ({ newsletter, language, data }) => (
  <div className="newsletter-teaser">
    <div className="newsletter-header">
      <Link to={newsletter.path.filter(p => p.lang === language)[0].p}>
        <Field entity={newsletter} field="title" tag="h2" data={data} />
      </Link>
      <Field entity={newsletter} field="teaser" data={data} />
    </div>
    <Field entity={newsletter} field="img" data={data} />
  </div>
);

const newslettersQuery = {
  collection: "node",
  query: {
    bundle: "newsletter"
  },
  sort: { _id: -1 },
  projection: {
    type: 1,
    bundle: 1,
    title: 1,
    path: 1,
    img: 1,
    teaser: 1,
    _i18n: 1
  }
};
const NewslettersRenderer = ({ field, value, data }) => (
  <Query q={newslettersQuery}>
    {(nodes, status) => {
      if (status !== "fulfilled")
        return <div className="loading">Se încarcă...</div>;
      return nodes
        .filter(
          n => data.language === "ro" || (n._i18n && n._i18n[data.language])
        )
        .map((n, i) => (
          <Newsletter
            key={i}
            language={data.language}
            newsletter={n}
            data={data}
          />
        ));
    }}
  </Query>
);

export default NewslettersRenderer;
