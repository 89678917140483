import React from 'react'
import {Helmet} from "react-helmet"
import {realPath} from '../../lib/util'
import {BASE, WEBSITE_NAME} from '../../config'
import Region from '../../ui/Region'
//import Slideshow from './Slideshow'

const Home = ({node, status, data}) =>
	<React.Fragment>
				{status !== 'exit' && status !== 'exiting' &&
				<Helmet>
					<title>{WEBSITE_NAME}</title>
        			<meta property="og:url" content={BASE} />
        			<meta property="og:type" content="article" />
        			<meta property="og:title" content={WEBSITE_NAME} />
        			<meta property="og:description" content="" />
					{node.thumb && node.thumb.length>0 && <meta property="og:image" content={realPath(node.thumb[0].url, 'thumb')} />}
					<meta property="og:locale" content="ro_RO" />
					<meta property="twitter:url" content={BASE} />
					<meta property="twitter:card" content="summary" />
					<meta property="twitter:title" content={WEBSITE_NAME} />
					<meta property="twitter:description" content="" />
				</Helmet>}
		<Region entity={node} data={data}/>
	</React.Fragment>

export default Home
