import React from "react";
//import PropTypes from 'prop-types'
import Text from "../ui/widgets/Text";
import BlockRenderer from "../components/lib/BlockRenderer";
import Link from "../ui/Link";
import Slideshow from "../components/lib/Slideshow";
import ProductsRenderer from "../components/product/ProductsRenderer";
import NewslettersRenderer from "../components/page/NewslettersRenderer";
import DocRenderer from "../components/product/DocRenderer";
import PDFLinkRenderer from "../components/product/PDFLinkRenderer";
import FormRenderer from "./renderer/FormRenderer";

const LinkRenderer = ({ field, value }) => {
  if (!value) return <div />;
  return (
    <div className={`field-${field}`}>
      <Link className="field-link" to={value.path || ""}>
        {value.label || ""}
      </Link>
    </div>
  );
};
const MapRenderer = ({ field, value, fieldInfo, data }) => {
  if (!value) return null;
  //console.log(field, props)
  const classes = `field-${field} ` + (fieldInfo._class || "");
  return (
    <div className={classes}>
      <div className="content">
        <iframe
          title="Harta"
          src={`${value}&hl=${data.language}`}
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen="1"
        />
      </div>
    </div>
  );
};
const PatentRenderer = ({ value, ...props }) => {
  //console.log(props)
  if (!value) return null;
  //return null
  return <Text className="patent-pending" value={value} {...props} />;
};

const Table = ({ data }) => {
  const rows = data.split("\n").map(row => row.split("|"));
  return (
    <table>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {row.map(
              (field, j) =>
                field.indexOf("<") >= 0 ? (
                  <td key={j} dangerouslySetInnerHTML={{ __html: field }} />
                ) : (
                  <td key={j}>{field}</td>
                )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TableRenderer = ({
  entity,
  entityInfo,
  field,
  value,
  fieldInfo,
  tag,
  data,
  ...props
}) => {
  return <div {...props}>{value && <Table data={value} />}</div>;
};
const fieldRenderer = {
  block: BlockRenderer,
  link: LinkRenderer,
  slideshow: Slideshow,
  products: ProductsRenderer,
  newsletters: NewslettersRenderer,
  doc: DocRenderer,
  pdf: PDFLinkRenderer,
  contact: FormRenderer,
  map: MapRenderer,
  patent: PatentRenderer,
  table: TableRenderer
};
export { fieldRenderer };
