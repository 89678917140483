import React from 'react'
import Query from '../../lib/Query'
import ProductsList from './ProductsList'
import {productNodesQuery} from './util'

const buildTree = nodes => {
	const tree = []
	nodes.forEach(node => delete node.children)
	nodes.forEach(node => {
		if (!node.parent) {
			tree.push(node)
			return
		}
		
		nodes.forEach(n => {
			if (n._id.$oid===node.parent.id) {
				if (!n.children) n.children = []
				n.children.push(node)
				//console.log('push')
			}
		})
	})
	//console.log(tree)
	return tree
}
const ProductsRenderer = ({field, value, data}) => 
	<Query q={productNodesQuery}>
		{(nodes, status) => {
			if (status!=='fulfilled') return <div className='loading'>Se încarcă...</div>
			return <ProductsList products={buildTree(nodes)} data={data}/>
		}}
	</Query>

export default ProductsRenderer