import React from 'react'
import PropTypes from 'prop-types'

export default class App extends React.Component {
    constructor(props) {
    	super(props)
    	this.key = 0
    	this.values = {}
    	this.listeners = {}
    }
    
	getChildContext() {
        return {
			get: name => this.values[name],
    	    set: state => {
    	    	Object.keys(state).forEach(name => {
    	    		const value = state[name]
	    	    	this.values[name] = value
					if(!this.listeners[name]) return
					for (let key in this.listeners[name]) {
						this.listeners[name][key](value)
					}
    	    	})
				this.forceUpdate();
    	    },
        	addListener: (name, cb) => {
				if (!this.listeners[name]) this.listeners[name] = {}
				this.key++
				this.listeners[name][this.key] = cb
				return this.key
        	},
        	removeListener: (name, key) => delete this.listeners[name][key],
        	addListeners: listeners => {
        		const keys = {}
    	    	Object.keys(listeners).forEach(name => {
					if (!this.listeners[name]) this.listeners[name] = {}
					this.key++
					this.listeners[name][this.key] = listeners[name]
					keys[name] = this.key
    	    	})
    	    	return keys
        	},
        	removeListeners: keys => {
    	    	Object.keys(keys).forEach(name => {
    	    		delete this.listeners[name][keys[name]]
    	    	})
        	}
        }
	}
    
	render() {
		return this.props.children
	}
}
App.childContextTypes = {
	get: PropTypes.func,
    set: PropTypes.func,
    addListener: PropTypes.func,
    removeListener: PropTypes.func,
    addListeners: PropTypes.func,
    removeListeners: PropTypes.func,
}

