//import {LANGUAGES} from '../../../config'
import {entityFields} from './entity'
import {nodeBundles, entityTypes} from './entityAdmin'
import {fieldTypes} from './field'

const getEntityInfo = (entity) => {
	let info = {}

	info.collection = entity.type==='node' 
		?'node' 
		:((entityTypes[entity.type] && entityTypes[entity.type].collection)
				?entityTypes[entity.type].collection
				:entity.type)

	info.fields = ((entity.type==='node')?entityFields[entity.bundle]:entityFields[entity.type]) || []
	//console.log(entity, info.fields)
	if (entity._e) {
		entity._e.forEach(field => {
			let found = false
			info.fields = info.fields.map(f => {
					if (f.name===field.name) {
						found = true
						return Object.assign({}, f, field)
					}
					return f
				})
			if (!found) info.fields.push(field)
		})
	}
	info.fields.forEach((f,i) => info.fields[i] = Object.assign({}, fieldTypes[f.type]?fieldTypes[f.type]:{}, f))
	
	if (entity.type === 'node') {
		info.typeInfo = nodeBundles[entity.bundle]
	} else {
		info.typeInfo = entityTypes[entity.type]
	}
	
	if (info.typeInfo && info.typeInfo.fields) {
		Object.keys(info.typeInfo.fields).forEach(fieldName => 
			info.fields = info.fields.map(field => 
				field.name===fieldName?Object.assign({}, field, info.typeInfo.fields[fieldName]):field)
		)
	}
	return info
}
export default getEntityInfo
