import React from 'react'
import renderers from './renderers'

const Any = ({value, entityInfo, fieldInfo, ...props}) => {
	if (!value || value.length===0) return null
	
	return 	<React.Fragment>
				{value.map((v, i) => {
					const Renderer = renderers[v.t]
					return <Renderer key={i} value={v.v} {...props}/>
				})}
			</React.Fragment>
}
export default Any
