import React from 'react'
import PropTypes from 'prop-types'
import {TweenMax, Elastic} from 'gsap'
import Field from '../../ui/Field'
//import FaIcon from '../../ui/FaIcon'
import Link from '../../ui/Link'
import { Transition, TransitionGroup } from 'react-transition-group'
import {push} from '../../lib/history'
import {screen} from '../../lib/screen'
import {subscribe, unsubscribe} from '../../lib/pubsub'

const classes = {
	exiting: 'product-detail exiting',
	exited: 'product-detail exited',
	entering: 'product-detail entering animated faster slideInRight',
	entered: 'product-detail entered'
}

const label = {
	ro: 'Detalii',
	en: 'Details'
}

class ProductsList extends React.PureComponent {
	constructor(props, context) {
		super(props, context)
		this.state = {
			selected: context.get('product-selected') ||'0',
			open: [],
			w: screen.W
		}
	}
	
	componentDidMount() {
		this.rid = subscribe('resize', this.onResize)
		//if (this.props.data.status==='entered') {
			this.animateIndexIn()
			/*setTimeout(() => {
				if (this.unmounted) return
				this.setState({selected: this.context.get('product-selected') ||'0'})
			}, 500)*/
		//}
	}
	componentWillUnmount() {
		this.unmounted = true
		unsubscribe('resize', this.rid)
	}
	onResize = (screen) => {
		if (this.unmounted) return
		this.setState({w: screen.W})
	}
	
	onSelectProduct = e => {
		const selected = e.currentTarget.dataset.path
		if (this._select) clearTimeout(this._select)
		this._select = setTimeout(() => {
			this.selectProduct(selected)
		}, 300)
	}
	onMouseOut = e => {
		if (this._select) clearTimeout(this._select)
	}
	selectProduct = (selected) => {
		//console.log(selected)
		//const {products} = this.props
		if (this.unmounted) return
		if (screen.W>900) {
			this.context.set({'product-selected': selected})
			this.setState({selected})
		}/* else {
			const language = this.context.get('language')
			push(products[selected].path.filter(p => p.lang===language)[0].p)
		}*/
	}
	
	onClick = e => {
		const {products, data} = this.props
		const selected = e.currentTarget.dataset.path.split(':')
		const language = data.language
		const selectedProd = selected.reduce((acc, val) => Array.isArray(acc)?acc[parseInt(val, 10)]:acc.children[parseInt(val, 10)], products)
		if (!selectedProd) return
		push(selectedProd.path.filter(p => p.lang===language)[0].p)
	}
	
	onToggle = e => {
		const {open} = this.state
		const path = e.currentTarget.dataset.path
		//console.log(path)
		if (open.indexOf(path)<0) this.setState({open: [...open, path]})
		else this.setState({open: open.filter(p => p!==path)})
	}
	renderIndexItem = (path, p, i) => {
		const {selected} = this.state
		const language = this.props.data.language
		//console.log(p)
		//const level = path.split(':').length
		let links = p.path.filter(pp => pp.lang===language)
		let link
		if (links.length>0) link = links[0].p
		else link = p.path[0].p
		
		/*if (p.children) {
			links = p.children[0].path.filter(pp => pp.lang===language)
			if (links.length>0) link = links[0].p
			else link = p.children[0].path[0].p
		}*/
		
		return	(
			<li key={i} className='level'>
				<div
					className={`item ${path===selected?'selected':''}`} 
					onMouseOver={this.onSelectProduct} 
					onMouseOut={this.onMouseOut} 
					//onClick={this.onClick}
					data-path={path}
					>
					<Link to={link}>{(p._i18n && p._i18n[language] && p._i18n[language].title)?p._i18n[language].title:p.title}</Link>
					{/*p.children && <div className='arrow'
						data-path={path}
						onClick={this.onToggle}>
							<FaIcon icon={open.indexOf(path)<0?'faAngleDown':'faAngleUp'}/>
						</div>
					*/}
				</div>
				{p.children && /*open.indexOf(path)>=0 && */
					<ul>
					{p.children.map((p1, i1) => this.renderIndexItem(`${path}:${i1}`, p1, i1))}
					</ul>
				}
			</li>
		)
	}
	
	renderSelectedDetails = (status, selectedStr) => {
		const {products, data} = this.props
		const language = data.language
		const selected = selectedStr
			?selectedStr.split(':')
			:[]
		const selectedProd = selected.reduce((acc, val) => Array.isArray(acc)?acc[parseInt(val, 10)]:acc.children[parseInt(val, 10)], products)
		if (selected.length===0) return null

		let links = selectedProd.path.filter(pp => pp.lang===language)
		let link
		if (links.length>0) link = links[0].p
		else link = selectedProd.path[0].p

		return (
			<div className={classes[status]}>
				<Field entity={selectedProd} field='img' data={data}/>
				<div className='header'>
					<h2>{(selectedProd._i18n && selectedProd._i18n[language] && selectedProd._i18n[language].title)?selectedProd._i18n[language].title:selectedProd.title}</h2>
					<div className='button-wrap'><Link to={link} className='button'>{label[language] || 'Detalii'}</Link></div>
				</div>
			</div>
		)
	}
	animateIndexIn = () => {
		//let selected = false
		TweenMax.staggerFromTo('.product-index-in li', 2, 
			{scale: .8, opacity: 0},
			{scale: 1, opacity: 1, delay:0.5, ease:Elastic.easeOut, force3D:true}, 
			//{rotationX: 90, transformOrigin: "50% 0", opacity: 0},
			//{rotationX: 0, transformOrigin: "50% 0", opacity: 1, delay:0.5, ease:Elastic.easeOut, force3D:true}, 
			.15)
	}
				/*onComplete: () => {
					if (this.unmounted || selected) return
					selected = true
					this.setState({selected: '0'})
				}*/
	componentDidUpdate(prevProps, prevState) {
		/*if (this.props.data.status!==prevProps.data.status && 
			this.props.data.status==='entered') {
				this.animateIndexIn()
		}*/


		const p = document.querySelector('.product-detail-in')
		if (!p) return
		const w = p.clientWidth
		if (w===0) return
		this.top = `${(screen.H - w)/2}px`
		p.style.top = this.top
	}
	render() {
		const {products} = this.props
		const {selected, w} = this.state
		
		const style= {}
		if (this.top) style.top = this.top
		return (
			<div className='product-list'>
				<div className='product-index'><div className='product-index-in'><ul>
					{products.map((p, i) => this.renderIndexItem(`${i}`, p, i))}
				</ul></div></div>
				{w>900 && <div className='product-detail-out'>
				<TransitionGroup className='product-detail-in' style={style} appear={true} enter={true}>
					<Transition key={selected} unmountOnExit={true} timeout={500}>
					{status => this.renderSelectedDetails(status, selected)}
					</Transition>
				</TransitionGroup>
				</div>	
				}
			</div>
		)
	}
}
ProductsList.contextTypes = {
    get: PropTypes.func,
    set: PropTypes.func,
}
export default ProductsList

/*
							<Link to={products[selected].path.filter(p => p.lang===language)[0].p} className='button'>Detalii</Link>
*/