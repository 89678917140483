//import {zones} from './config'

const entityFields = {
  homepage: [],
  page: [
    { name: "title", type: "text", label: "Titlu", region: "title" },
    //{name: 'img', type: 'img', label: 'Imagine', single: true},
    { name: "body", type: "html", label: "Conținut" }
  ],
  newsletter: [
    { name: "title", type: "text", label: "Titlu", region: "title" },
    { name: "body", type: "html", label: "Conținut" },
    {
      name: "img",
      type: "img",
      label: "Imagine Teaser",
      single: true,
      region: "teaser"
    },
    { name: "teaser", type: "html", label: "Teaser", region: "teaser" }
  ],
  product: [
    { name: "parent", type: "reference", label: "Părinte" },
    { name: "title", type: "text", label: "Titlu", region: "title" },
    { name: "gid", type: "text", label: "ID Google", region: "gtag" },
    {
      name: "img",
      type: "img",
      label: "Imagine",
      single: true,
      region: "left"
    },
    { name: "body", type: "html", label: "Conținut", region: "right" },
    { name: "fise", type: "doc", label: "Fise tehnice", region: "right" },
    { name: "order", type: "int", label: "Ordine", _t: false }
  ],
  address: [
    { name: "address", type: "text", label: "Adresa" },
    { name: "phone", type: "text", label: "Telefon" },
    { name: "fax", type: "text", label: "FAX" }
  ]
};
const entityLayout = {
  page: {
    content: ["body", "grid"]
  }
};

export { entityFields, entityLayout };
