import NProgress from 'nprogress'

const stack = []

const progressStart = () => {
	if(typeof window === 'undefined') return
	stack.push(1)
	//if (stack.length===1) NProgress.start()
}

const progressSet = (step) => {
	if(typeof window === 'undefined') return
	NProgress.set(step)
}

const progressDone = () => {
	if(typeof window === 'undefined') return
	stack.pop()
	if (stack.length===0) NProgress.done()
}

const progressReparent = (parent = 'body') => {
	if(typeof window === 'undefined') return
	NProgress.configure({parent})
}

export {progressStart, progressSet, progressDone, progressReparent}