import React, {Fragment} from 'react'
//import NProgressBar from '../ui/NProgressBar'
import Header from './layout/Header'
import Node from './layout/Node'
//import CookiePolicy from './layout/CookiePolicy'

const Layout = () =>
			<Fragment>
				<Header/>
	  			<Node/>
  				<div id='modal-container'/>
    		</Fragment>
export default Layout
