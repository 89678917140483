import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {LANGUAGES} from '../../config'
import Link from '../../ui/Link'

export default class LanguageSwitcher extends Component {
	constructor(props, context) {
		super(props, context)

		this.state = {
			node: context.get('node'),
		}
	}

    componentDidMount() {
    	this.nid = this.context.addListener('node', this.handleNodeChange)
    }

	componentWillUnmount() {
		this.unmounted = true
    	this.context.removeListener('node', this.nid)
    }

	handleNodeChange = node => {
		if (this.unmounted) return
		this.setState({node})
	}

	onActivate = e => {
		this.context.set({enter: 'fade'})
		return true
	}
	render() {
		const {node} = this.state
		const language = this.context.get('language') || 'ro'
		
		return (
			<ul className='language-switcher'>
				{LANGUAGES.map((lang, i) => {
					let path = `/${lang}`
					if (node && node.path) {
						const paths = node.path.filter(p => p.lang===lang)
						if (paths.length>0) path = paths[0].p
					}
					return <li key={i}><Link to={path} className={language===lang?'active':''} onActivate={this.onActivate}>{lang}</Link></li>
				})}
			</ul>
		)
	}
}
LanguageSwitcher.contextTypes = {
    set: PropTypes.func,
    get: PropTypes.func,
    addListener: PropTypes.func,
    removeListener: PropTypes.func
}
