import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import {addLocationListener} from '../../lib/history'
//import {query, registerQuery, unregisterQuery} from '../../lib/api'
import NodeInner from './NodeInner'
import getNode from '../lib/node'
import {registerQuery, unregisterQuery} from '../../lib/api'
import {LANGUAGES} from '../../config'

class Node extends PureComponent {
	constructor(props, context) {
		super(props, context)
		
		this.location = typeof window === 'undefined'
			?global.location
			:window.location.pathname
		if (LANGUAGES) this.updateLanguage(this.location)	
		
		this.first = true
		this.requestNode(false) //false
		this.enter = context.get('enter')
		
		this.state = {
			location: this.location,
			node: null,
			language: this.lang,
			enter: null,
			user: context.get('user')
		}
		if(!(this.data instanceof Promise)) {
			const node = (this.data && this.data.results && this.data.results.length>0)?this.data.results[0]:null
			this.state.node = node
			context.set({node})
		} else {
			if (typeof window === 'undefined') this.data = null
		}
	}
	
	componentDidMount() {
		addLocationListener(this.handleLocationChange)
		if(this.data instanceof Promise) this.waitForPromise()
		if (this.query) this.qid = registerQuery(this.query, this.onResults)
    	this.uid = this.context.addListener('user', this.handleUserChange)
	}
	
	componentWillUnmount() {
		this.unmounted = true
		if (this.qid) unregisterQuery(this.qid)
    	this.context.removeListener('user', this.uid)
		//unsubscribe('resize', this.rid)
	}

	handleUserChange = user => {
		if (this.unmounted) return
		this.setState({user})
	}
	
	requestNode = (register=true) => {
		if (this.qid) unregisterQuery(this.qid)
		const {query, data} = getNode(this.location, register?this.onResults:null)
		if (!register) this.query = query
		this.data = data
	}
	
	onResults = data => {
		if (this.unmounted) return
		this.data = data
		if (!this.data || !this.data.results || this.data.results.length===0) {
			this.setState({
				location: this.location,
				node: null,
				language: null
			})
			this.context.set({node: null})
			return
		}
		const node = this.data.results[0]
		const enter = this.context.get('enter')
		this.context.set({enter: null})
		this.setState({
			location: this.location,
			node,
			language: this.lang,
			enter
		})
		this.context.set({node: this.data.results[0]})
	}
	
	waitForPromise = () => {
		if(!(this.data instanceof Promise)) return this.onResults(this.data)
		this.data.then(data => this.onResults(data))
	}
		
	handleLocationChange = (location, search) => {
		if (this.unmounted) return
		this.location = location
		this.search = search
		//console.log(location, search)
		if (LANGUAGES) this.updateLanguage(location)
		this.first = false
		this.requestNode()
		this.waitForPromise()
	}
	updateLanguage = location => {
		const toks = location.split('/')
		if (toks.length<2) return
		const lang = toks[1]
		this.lang = lang
		if (LANGUAGES.indexOf(lang)<0) this.context.set({language: LANGUAGES[0]})
		else this.context.set({language: lang})
	}
	render() {
		const {node, location, user, enter, language} = this.state
		
		if (!node) return null
		
		const classes = `node node-${this.first?'first':'notfirst'}`
		return	<TransitionGroup className={classes} appear={true} enter={true}>
					<Transition key={location} unmountOnExit={true}
						timeout={1000}>
						{status => <NodeInner user={user} location={location} node={node} search={this.search} first={this.first}
							data={{status, enter, language}}/>}
					</Transition>
				</TransitionGroup>
		
	}
}
Node.contextTypes = {
    set: PropTypes.func,
    get: PropTypes.func,
    addListener: PropTypes.func,
    removeListener: PropTypes.func,
}

export default Node
