import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Helmet} from "react-helmet"
import {BASE, WEBSITE_NAME} from '../../config'
import {realPath} from '../../lib/util'
import {query} from '../../lib/api'
import Field from '../../ui/Field'
import Region from '../../ui/Region'
import Hammer from 'hammerjs'
import {productNodesQuery} from './util'
import {push} from '../../lib/history'
import Link from '../../ui/Link'
import FaIcon from '../../ui/FaIcon'

const back = {
    ro: {
	title: 'Produse',
	path: '/ro/produse'
    },
    en: {
	title: 'Products',
	path: '/en/products'
    },
    de: {
	title: 'Produkte',
	path: '/de/produkte'
    },
    hu: {
	title: 'Products',
	path: '/hu/products'
    }
}

class ProductNode extends PureComponent {
	static contextTypes = {
        getScrollbar: PropTypes.func
    }
    state = {
    	prev: null,
    	next: null
    }

	componentWillUnmount() {
		this.unmounted = true
		//animation.kill()
        this.hammer.off('swiperight', this.prev)
        this.hammer.off('swipeleft', this.next)
	}

    scrollDown = e => {
		this.context.getScrollbar((scrollbar) => {
	    	scrollbar.scrollIntoView(this.content, {offsetTop: 125})
	    })
    }
    componentDidMount() {
		//const {node} = this.props
		this.getProductList()
    	this.hammer = new Hammer(document.querySelector('.node-product'))
        this.hammer.on('swiperight', this.prev)
        this.hammer.on('swipeleft', this.next)
    }

    getProductList = () => {
        const res = query(productNodesQuery)
    	if (res instanceof Promise) {
    		res.then(data => {
    			if (this.unmounted) return
    			//console.log(data)
    			if (data && data.results) {
    				this.setPrevNext(data.results)
    				//const productNodes = data.results
    				//this.setState({productNodes})
    			}
    		})
    	} else {
    		if (res && res.results) {
    			//console.log(res)
   				this.setPrevNext(res.results)
   				//const productNodes = res.results
   				//this.setState({productNodes})
   			}
   		}
    }
    setPrevNext = nodes => {
    	const {node} = this.props

		let index = -1
		nodes.forEach((n, i) => {if (n._id.$oid === node._id.$oid) index = i})
		//console.log(index)
		let prev = index - 1
		if (prev<0) prev = nodes.length - 1
		let next = index + 1
		if (next>=nodes.length) next = 0
		this.setState({
			prev: nodes[prev],
			next: nodes[next],
		})
    }
    prev = e => {
    	const {prev} = this.state
    	if (!prev) return

    	const path = prev.path[0].p
    	this.context.set({enter: 'left'})
    	push (path)
    }
    next = e => {
    	const {next} = this.state
    	//console.log(next)
    	if (!next) return

    	const path = next.path[0].p
    	this.context.set({enter: 'right'})
    	//push (`${path}?slide=right`)
    	push (path)
    }

    onBack = e => {
    	this.context.set({enter: 'left'})
    	return true
    }

    render() {
		const {node, status, data} = this.props
		//const {visits} = this.state
		// date - 32, sections 78, gallery 59 = 169
		//const h = screen.H * node.img[0].w / screen.W * 2
		//const noVisits = visits || node._visits
		const classes = (node.img && node.img.length>0)
			?(node.img[0].w>node.img[0].h
				?'page-header landscape'
				:'page-header portrait')
			:'page-header'
		const href = typeof window === 'undefined'
			?`${BASE}${global.location}`
			:window.location.href
		const language = data.language

		return (
			<Fragment>
				{status !== 'exit' &&
				<Helmet>
					<title>{`${WEBSITE_NAME} - ${node.title}`}</title>
        			<meta property="og:url" content={href} />
        			<meta property="og:type" content="article" />
        			<meta property="og:title" content={`${WEBSITE_NAME} - ${node.title}`} />
        			<meta property="og:description" content="" />
					{node.thumb && node.thumb.length>0 && <meta property="og:image" content={realPath(node.thumb[0].url, 'thumb')} />}
					<meta property="og:locale" content="ro_RO" />
					<meta property="twitter:url" content={href} />
					<meta property="twitter:card" content="summary" />
					<meta property="twitter:title" content={`${WEBSITE_NAME} - ${node.title}`} />
					<meta property="twitter:description" content="" />
				</Helmet>}
				<div className={classes} ref={ref=>this.header=ref}>
					<Link onActivate={this.onBack} to={back[language].path} className='products-backlink'><FaIcon icon='faAngleLeft' /> {back[language].title}</Link>
					<Field entity={node} field='title' tag='h1' data={data}/>
				</div>
				<div className='page-content'>
					<Region entity={node} region='left' data={data}/>
					<Region entity={node} region='right' data={data}/>
				</div>
			</Fragment>
    	)
    }
}
ProductNode.contextTypes = {
    get: PropTypes.func,
    set: PropTypes.func,
}
export default ProductNode
/*
ref={ref => this.pageNode=ref}
				style={{backgroundImage: 'url(/img/img-prod.jpg)'}}>
				<div className='page-node-content-in' style={{maxWidth}}>
				*/
