import {
	entityFields as customEntityFields, 
	entityLayout as entityLayouts
} from '../../../conf/entity'
//import {regions} from '../../conf/layout'
//import {nodeBundles} from './entityAdmin'
/*
const getRegionOptions = () => {
	return Object.keys(regions).map(region => ({val: region, label: regions[region].name}))
}
const getBundleOptions = () => {
	return Object.keys(nodeBundles).map(bundle => ({val: bundle, label: nodeBundles[bundle].name}))
}
*/
const entityFields = {
	/*page: [
		{name: 'title', type: 'text', label: 'Titlu', t: true}
	],*/
}

Object.keys(customEntityFields).forEach(entityType => {
	if (entityFields[entityType]) {
		customEntityFields[entityType].forEach(field => {
			let override = false
			entityFields[entityType] = entityFields[entityType].map(f => {
				if(f.name === field.name) {
					override = true
					return field
				}
				return f
			})
			if(!override) entityFields[entityType].push(field)
		})
	} else {
		entityFields[entityType] = customEntityFields[entityType]
	}
})
const getEntityFields = entity => {
	if(!entity) return []
	let fields = (entity.type==='node')?entityFields[entity.bundle]:entityFields[entity.type]
	if (entity._e) fields = fields.concat(entity._e)
	/*if (entity.e) {
		try {
			fields = fields.concat(JSON.parse(entity.e))
		}catch(e) {
			console.log(e)
		}
	}*/
	return fields
}
const getEntityField = (entity, fieldName) => {
	if(!entity) return null
	let fields = getEntityFields(entity)
	if (!fields) return null
	fields = fields.filter(f => f.name===fieldName)
	return fields.length>0?fields[0]:null
}

const entityLayout = entity => {
        if (!entity) return null
        return entityLayouts[entity.bundle]
}
export {entityFields, entityLayout, getEntityFields, getEntityField}
