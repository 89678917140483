import auth from '../authorize'

let permissions = {}
Object.keys(auth).forEach(perm => {
	if(perm==='entity') return
	permissions[perm] = {roles: auth[perm]}
})

class User {
	constructor(profile) {
		this.profile = profile
		if (profile) {
			//this.isAdmin = profile.roles.includes('admin')
        	//this.isEditor = profile.roles.includes('admin') || profile.roles.includes('editor')
			this.isAdmin = profile['https://linuxship.ro/authorization/roles'].indexOf('admin')>=0
        	this.isEditor = profile['https://linuxship.ro/authorization/roles'].indexOf('admin')>=0 ||
        		profile['https://linuxship.ro/authorization/roles'].indexOf('editor')>=0
		}
	}
	
	can = (perm, data=null) => {
		if(this.isAdmin || this.isEditor) return true
		//console.log(permissions)
		if(!permissions[perm]) return false
		const pdata = permissions[perm]
		if (pdata.cb) return pdata.cb(this, perm, data)

		if (this.profile && pdata.roles) return this.profile.roles.reduce((acc, role) => pdata.roles.includes(role)||acc, false)
	
		return false
	}
}
export default User