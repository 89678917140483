import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { BASE, WEBSITE_NAME, LANGUAGES } from "../../config";
//import Link from '../../ui/Link'
import { realPath } from "../../lib/util";
import { query } from "../../lib/api";
//import LazyImage from '../../ui/LazyImage'
import Field from "../../ui/Field";
import Region from "../../ui/Region";

//import Promo from '../layout/Promo'
//import Banner from '../layout/Banner'
//import animation from './animation'
//import FBLike from '../../ui/FBLike'
//import FBComments from '../../ui/FBComments'

class PageNode extends PureComponent {
  static contextTypes = {
    getScrollbar: PropTypes.func
  };
  state = {
    visits: false
  };

  componentWillUnmount() {
    this.unmounted = true;
    //animation.kill()
  }

  scrollDown = e => {
    this.context.getScrollbar(scrollbar => {
      scrollbar.scrollIntoView(this.content, { offsetTop: 125 });
    });
  };
  componentDidMount() {
    const { node } = this.props;
    if (window.isHydrating && node) {
      const res = query({
        query: { _id: node._id.$oid },
        projection: { _visits: 1 }
      });
      if (res instanceof Promise) {
        res.then(data => {
          if (this.unmounted) return;
          //console.log(data)
          if (data && data.results && data.results.length > 0) {
            const visits = data.results[0]._visits;
            this.setState({ visits });
          }
        });
      } else {
        if (res && res.results && res.results.length > 0) {
          //console.log(res)
          const visits = res.results[0]._visits;
          this.setState({ visits });
        }
      }
    }
  }
  render() {
    const { node, status, data } = this.props;
    //const {visits} = this.state
    // date - 32, sections 78, gallery 59 = 169
    //const h = screen.H * node.img[0].w / screen.W * 2
    //const noVisits = visits || node._visits
    const classes =
      node.img && node.img.length > 0
        ? node.img[0].w > node.img[0].h
          ? "page-header landscape"
          : "page-header portrait"
        : "page-header";
    const href =
      typeof window === "undefined"
        ? `${BASE}${global.location}`
        : window.location.href;
    let title = node.title;
    //console.log(this.props);

    if (LANGUAGES) {
      const lang = data.language;
      if (
        lang !== LANGUAGES[0] &&
        node &&
        node._i18n &&
        node._i18n[lang] &&
        node._i18n[lang].title !== undefined
      )
        title = node._i18n[lang].title;
    }
    //console.log(title);
    return (
      <Fragment>
        {status !== "exit" &&
          status !== "exiting" && (
            <Helmet>
              <title>{`${WEBSITE_NAME} - ${title}`}</title>
              <meta property="og:url" content={href} />
              <meta property="og:type" content="article" />
              <meta
                property="og:title"
                content={`${WEBSITE_NAME} - ${title}`}
              />
              <meta property="og:description" content="" />
              {node.thumb &&
                node.thumb.length > 0 && (
                  <meta
                    property="og:image"
                    content={realPath(node.thumb[0].url, "thumb")}
                  />
                )}
              <meta property="og:locale" content="ro_RO" />
              <meta property="twitter:url" content={href} />
              <meta property="twitter:card" content="summary" />
              <meta
                property="twitter:title"
                content={`${WEBSITE_NAME} - ${title}`}
              />
              <meta property="twitter:description" content="" />
            </Helmet>
          )}
        <div className={classes}>
          <Field entity={node} field="title" tag="h1" data={data} />
        </div>
        <div className="page-content">
          <Region entity={node} data={data} />
        </div>
      </Fragment>
    );
  }
}

export default PageNode;

/*
					<div className='right'><div className='right-in'>
						<Promo/>
						<Banner zone='8'/>
					</div></div>
*/
