import React, { Component } from 'react';
//import fastdom from 'fastdom'
import {screen} from '../lib/screen'
import {realPath} from '../lib/util'
//import imagePrecache from './ImagePrecache'
import {styles} from '../config'
import {intersectionObserve, intersectionUnobserve} from '../lib/intersection'
import {subscribe, unsubscribe} from '../lib/pubsub'

const cache = {}

export default class LazyImage extends Component {
	state = {
		loaded: false
	}
	static getDerivedStateFromProps(props) {
		const {src, noth, className, imageStyle} = props

		if(!src) return {}

		//this.loaded = false
		let img
		if (src.th && !noth) img = `data:image/jpeg;charset=utf-8;base64, ${src.th}`
		else img = src.url

		let url
		if (src.srcset) {
			const srcset = src.srcset.filter(item =>
				(!item.mq || item.mq.length===0 || item.mq.indexOf(screen.MQ)>=0) &&
				(!item.o || item.o.length===0 || item.o.indexOf(screen.O)>=0) &&
				(!item.r || item.r.length===0 || item.r.indexOf(screen.RATIO)>=0)
			)
			if (srcset.length ===0) url = realPath(src.url, imageStyle)
			else url = realPath(srcset[0].src, imageStyle)
		} else url = realPath(src.url, imageStyle)

		let classes = className || ''
		const aspect = imageStyle
			?(styles[imageStyle][1] / styles[imageStyle][0] * 100)
			:(src.h?(src.h / src.w * 100):1)
		let style = props.style || {}
		let containerStyle, propContainerStyle

		if (props.bg) {
			if (props.proportional) {
				classes = `${classes} prop-container`
				propContainerStyle = {height:0, paddingBottom: `${aspect}%`}
				containerStyle = {backgroundImage: `url('${img}')`, ...style}
			} else {
				classes = `${classes} lazy`
				containerStyle = {backgroundImage: `url('${img}')`, ...style}
			}
		} else {
			if (props.proportional) {
				classes = `${classes} prop-container`
				propContainerStyle = {height:0, paddingBottom: `${aspect}%`, ...style}
			} else {
				classes = `${classes} lazy`
			}
		}

		let state = {url, img, classes, aspect, style, containerStyle, propContainerStyle}
		if (cache[url]) {
			//state = Object.assign({}, state, this.imageLoaded(props, state))
			state.img = state.url
			state.containerStyle = props.bg
				?{backgroundImage: `url('${state.img}')`, ...state.style}
				:state.containerStyle
			state.loaded = true
		} else state.loaded = false
		return state
	}

	imageLoaded = (props, state) => {
		const {bg} = props
		const {url, style} = state

		const img = url
		const containerStyle = bg
			?{backgroundImage: `url('${img}')`, ...style}
			:state.containerStyle
		return {img, containerStyle}
	}

	componentDidMount() {
		const {src, preload} = this.props
		if (!src) return

		if (this.img) intersectionObserve(this.img, this.onIntersect)
		if (src && src.srcset) this.sid = subscribe('resize', this.onResize)

		if (preload && !this.state.loaded) {
			this.setState({loaded: true})
			setTimeout(() => this.loadImage())
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.src === prevProps.src && !this.resized) return
		this.resized = false
		if (this.img) {
			intersectionUnobserve(this.img)
			intersectionObserve(this.img, this.onIntersect)
		}
	}

	componentWillUnmount() {
		this.unmounted = true
		if (this.img) intersectionUnobserve(this.img)
		if (this.sid) unsubscribe('resize', this.sid)
	}

	onResize = () => {
		if (this.unmounted) return
		this.setState(LazyImage.getDerivedStateFromProps(this.props))
		this.resized = true
	}

	onIntersect = entry => {
		//console.log(entry)
		if (this.state.loaded || !entry.isIntersecting || this.unmounted) return
		this.setState({loaded: true}, )
		setTimeout(() => this.loadImage())
	}

	loadImage = () => {
		const {url} = this.state

		const img = new Image()
		img.onload = () => {
			if (this.unmounted) return
			cache[url] = true
			this.setState(this.imageLoaded(this.props, this.state))
		}
		img.onerror = () => {
			if (this.unmounted) return
			console.log(`Error loading image ${url}`)
		}
		img.src = url
		if (img.complete) {
			cache[url] = true
			this.setState(this.imageLoaded(this.props, this.state))
		}
	}

	render() {
		const { src, className, imageStyle, bg, alt, proportional, noth, data, ...rest } = this.props
		const {img, classes, style, containerStyle, propContainerStyle} = this.state

		if (!img) return null
		//console.log(rest)
		if (bg) {
			if (proportional) {
				return (
					<div className={classes} style={propContainerStyle}>
						<div ref={c => this.img = c} className='lazy' style={containerStyle} {...rest}>
							{this.props.children}
						</div>
					</div>
				)

			}
			return (
				<div ref={c => this.img = c} className={classes} style={containerStyle} {...rest}>
					{this.props.children}
				</div>
			)
		}
		if (proportional) {
			return (
				<div className={classes} style={propContainerStyle} {...rest}>
					<img ref={c => this.img = c} src={img} className='lazy' alt={alt || ''} />
				</div>
			)
		}
		return <img ref={c => this.img = c} src={img} className={classes} alt={alt || ''} style={style} {...rest}/>
	}
}
