import React from 'react'
import LazyImage from '../../ui/LazyImage'
import Field from '../../ui/Field'

const BlockRenderer = (props) => {
	const {field, value, entityInfo, fieldInfo, ...other} = props

    if(!value) return <div/>
    
    const fieldProps = {}
    if (fieldInfo.id) fieldProps.id = fieldInfo.id
    const classes = `field-${field} block ` + (fieldInfo._class?fieldInfo._class:'')

	const allFields = entityInfo.fields.filter(f => f._block===field).map(f => f.name)
	let fields = value._o?value._o.split(','):[]
	fields = fields.filter(f => allFields.indexOf(f)>=0)
	fields = fields.concat(allFields.filter(f => fields.indexOf(f)<0))
		//:entityInfo.fields.filter(f => f._block===field).map(f => f.name)
	//console.log(props, fields)
    return <div className={classes} {...fieldProps}>
    	{value.bg && value.bg.length>0 && <LazyImage className='bg' bg={true} src={value.bg[0]}/>}

		<div className='block-content'>
       	{fields.map((f,i) => 
       		<Field key={i} field={f} entityInfo={entityInfo} 
       			fieldInfo={entityInfo.fields.filter(f1 => f1.name===f)[0]} 
       			{...other}/>)}
       	</div>
    </div>
}
export default BlockRenderer

