import React from 'react'
import {query, registerQuery, unregisterQuery, hash} from './api'

class Query extends React.PureComponent {
	constructor(props) {
		super(props)
		
		this.state = {id: 0, value: props.single?null:[], status: 'pending', hash: null}
		if (props.q) {
			this.state.hash = hash(props.q)
			this.data = query(props.q)
			if(!(this.data instanceof Promise)) {
				this.state.value = props.single
					?((this.data.results && this.data.results.length>0)?this.data.results[0]:null)
					:this.data.results
				this.state.total = this.data.total
				this.state.status = 'fulfilled'
				//if (props.onData) props.onData(this.state.value, 'fulfilled')
			} else {
				if (typeof window === 'undefined') this.data = null
			}
		}
	}
	
	componentDidMount() {
		const {q} = this.props
		if(!q) return
		
		if (this.data instanceof Promise) {
			this.data
				.then(data => this.onPromisedResults(q, data))
				.catch(error => {console.log(error)})
		} else this.qid = registerQuery(q, this.onData)
	}
	
	componentWillReceiveProps({q}) {
		const {id} = this.state
		
		if (!q) {
			this.setState({
				id: id + 1,
				hash: null,
				value: [],
				total: 0,
				status: 'fulfilled'
			})
			return
		}
		
		const h = hash(q)
		if (h===this.state.hash) return
		
		if (this.qid) {
			unregisterQuery(this.qid)
			this.qid = null
		}
		
		this.data = query(q)
		if(!(this.data instanceof Promise)) {
			this.qid = registerQuery(q, this.onData)
			this.setState({
				id: id + 1,
				hash: h,
				value: this.props.single
					?((this.data.results && this.data.results.length>0)?this.data.results[0]:null)
					:this.data.results,
				total: this.data.total,
				status: 'fulfilled'
			})
		} else {
			this.setState({
				id: id + 1,
				hash: h,
				status: 'pending'
			})
			this.data
				.then(data => this.onPromisedResults(q, data))
				.catch(error => {console.log(error)})
		}
	}
	onPromisedResults = (q, data) => {
		if (this.unmounted) return
		this.qid = registerQuery(q, this.onData)
		this.onData(data)
	}
	onData = data => {
		const {single} = this.props
		
		if (this.unmounted) return
		this.data = data
		const value = single
				?((data.results && data.results.length>0)?data.results[0]:null)
				:data.results
		this.setState({
			value,
			total: data.total,
			status: 'fulfilled'
		})
		//if (this.props.onData) this.props.onData(value, 'fulfilled')
	}
	
	componentWillUnmount() {
		this.unmounted = true
		if (this.qid) unregisterQuery(this.qid)
	}
	
	render() {
		const {value, status, id, total} = this.state
		//console.log(this.props)
		//console.log(this.state)
		return this.props.children(value, status, id, total)
	}
}
export default Query
