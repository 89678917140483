import {fieldTypes as customFieldTypes} from '../../../conf/field'
const baseFieldTypes = {
	text: 'Text simplu',
	html: 'HTML',
	img: 'Imagine',
	vid: 'Video',
	video: 'Link Video',
	doc: 'Doc',
	bool: 'Bool',
	'class': 'Clasă',
	radio: 'Butoane radio',
	list: 'Listă',
	obj: 'Obiect',
	custom: 'Personalizat'
}

let fieldTypes = {}
Object.keys(baseFieldTypes).forEach(type => fieldTypes[type] = {name: baseFieldTypes[type], type: type})
Object.keys(customFieldTypes).forEach(type => {
	if (fieldTypes[type]) fieldTypes[type] = Object.assign({}, fieldTypes[type], customFieldTypes[type])
	else fieldTypes[type] = customFieldTypes[type]
})

export {fieldTypes}