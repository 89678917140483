import React from "react";
import Home from "../home/Home";
import PageNode from "../page/PageNode";
import NewsletterNode from "../page/NewsletterNode";
import ProductNode from "../product/ProductNode";
import Footer from "./Footer";
import { pageView } from "../../lib/gtag";

const UnknownType = props => null;

const nodeTypes = {
  homepage: Home,
  page: PageNode,
  newsletter: NewsletterNode,
  product: ProductNode,
  unknown: UnknownType
};

const NodeInner = ({ user, node, first, search, data }) => {
  const bundle = node && nodeTypes[node.bundle] ? node.bundle : "unknown";
  const { status, enter } = data;

  let animation = "";
  if (status === "entering") {
    pageView(node, data.language);
    switch (enter) {
      case "left":
        animation = "animated slideInLeft";
        break;
      case "right":
        animation = "animated slideInRight";
        break;
      case "fade":
        animation = "animated fadeIn";
        break;
      default:
        animation = "animated fadeInUpSmall";
    }
  }
  const Comp = nodeTypes[bundle] || UnknownType;
  const classes = `node-content node-${bundle} node-${status} ${animation}`;

  let style = {}; //...styles[status]}
  if (status === "exiting") {
    style.transform = `translate(0, -${window.scrollY}px)`;
    window.scrollTo(0, 0);
  }

  return (
    <div className={classes} id={node._cssid}>
      <div className="page-bg" />
      <div className={`page-node-content ${status}`} style={style}>
        <div className="page-node-content-in">
          <Comp
            status={status}
            node={node}
            first={first}
            user={user}
            data={data}
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default NodeInner;
